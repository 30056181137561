import {
  DropDownBox, NumberBox, Button as DXButton,
  //DropDownButton
} from "devextreme-react";
import DataGrid, {
  Button as GridButton,
  Column,
  ColumnChooser,
  FilterRow,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid"
import { TextBox } from "devextreme-react";
import Form, { GroupItem, SimpleItem } from "devextreme-react/form";
import { Tooltip } from "devextreme-react/tooltip";
import notify from "devextreme/ui/notify";
import { default as React } from "react";
import { useHistory, useLocation } from "react-router";
import { fetchAllInstructors } from "../../api/auth";
import { confirm } from "devextreme/ui/dialog"
import {
  createCohort,
  fetchCohortById,
  sendNotificationToCohort,
  sendSmsToCohort,
  updateCohort,
} from "../../api/cohort";
import { getAllCourses } from "../../api/courses";
import { fetchFarmers } from "../../api/farmers";
import {
  MessagePopup, Select,Spinner, Button, MapLayer
  //Dropdown
} from "../../components";
import { useLogin } from "../../cookies";
import { getAvatarColor, getFirstLetters, notificationCohort } from "../../utils/utils";
import "./cohort.scss";
import { getCohortEditPath, getCohortsPath } from '../../app-routes'
import { useProgram } from '../../programs'
import { useMemo } from "react";

const validationRules = {
  name: [{ type: "required", message: "Cohort name is required" }],
};

const CohortData = () => {
  const program = useProgram()
  const [farmersData, setFarmersData] = React.useState([]);
  const [stampSelectedFarmers, setStampSelectedFarmers] = React.useState([]);
  const [farmersToDelete, setFarmersToDelete] = React.useState([]);
  const [selectedFarmers, setSelectedFarmers] = React.useState([]);
  const [instructors, setInstructors] = React.useState([]);
  const [stampSelectedCourses, setStampSelectedCourses] = React.useState([]);
  const [courseStatus, setCourseStatus] = React.useState([]);
  const [courseSessionHours, setCourseSessionHours] = React.useState([]);
  const [isDropDownOpen, setIsDropDownOpen] = React.useState(false);
  const [selectedInstructors, setSelectedInstructors] = React.useState({
    firstName: "",
    lastName: "",
  });
  const [allCourses, setAllCourses] = React.useState([]);
  const [selectedCourses, setSelectedCourses] = React.useState([]);
  const [cohortInfo, setCohortInfo] = React.useState({
    name: "",
    gpsPoint : null
  });
  const [showSmsPopup, setShowSmsPopup] = React.useState(false);
  const [messageSms, setMessageSms] = React.useState([]);
  const { token } = useLogin();
  const instructorDataRef = React.useRef(null);
  const history = useHistory();
  const participantsDataRef = React.useRef(null);
  const farmersDataRef = React.useRef(null);
  let courseDataRef = React.useRef(null);
  const { pathname } = useLocation();

  const [loading, setLoading] = React.useState(false);
  const [loadingCohort, setLoadingCohort] = React.useState(true);
  const [loadingCourse, setLoadingCourse] = React.useState(true);
  const [loadingParticipants, setLoadingParticipants] = React.useState(true);
  const isMounted = React.useRef(false);
  const [options, setOptions] = React.useState({
    method : {
      label : "SMS or Notification ?",
      value : "Notification",
      messagePreview : "Description :",
      dataValue : "method"
    }
  })

  const dropdownRef = React.useRef(null);


  // COLUMNS OF THE 3 TABLES + INSTRUCTOR FIELD
  const allParticipantsColumns = [
    { key: "fullname", label: "Full name", visible: true },
    { key: "district", label: "District", visible: true },
    { key: "subdistrict", label: "Subdistrict", visible: true },
    { key: "firstName", label: "First name", visible: false },
    { key: "lastName", label: "Last name", visible: false },
    { key: "phoneNumber", label: "Phone number", visible: false },
    { key: "participantCode", label: "Participant code", visible: false },
    {
      key: "enrollDate",
      label: "Enrollment date",
      dataType: "datetime",
      visible: false,
    },
    {
      key: "firstAccessDate",
      label: "First access date",
      dataType: "datetime",
      visible: false,
    },
    {
      key: "lastAccessDate",
      label: "Last access date",
      dateType: "datetime",
      visible: false,
    },
    {
      key: "userAgent",
      label: "Device Details",
      visible: false,
    },
    { key: "oldestCropPlot", label: "Oldest Crop Date", visible: false }
  ];
  const selectedParticipantsColumns = [
    { key: "fullname", label: "Full name", visible: true  },
    { key: "district", label: "District", visible: false },
    { key: "subdistrict", label: "Subdistrict", visible: false },
    { key: "firstName", label: "First name", visible: false },
    { key: "lastName", label: "Last name", visible: false },
    { key: "phoneNumber", label: "Phone number", visible: false },
    { key: "participantCode", label: "Participant code", visible: false },
    {
      key: "enrollDate",
      label: "Enrollment date",
      dataType: "datetime",
      visible: false,
    },
    {
      key: "firstAccessDate",
      label: "First access date",
      dataType: "datetime",
      visible: false,
    },
    {
      key: "lastAccessDate",
      label: "Last access date",
      dateType: "datetime",
      visible: false,
    },
    {
      key: "userAgent",
      label: "Device Details",
      visible: false,
    },
    { key: "oldestCropPlot", label: "Oldest Crop Date", visible: false }
  ];
  const instructorColumns = [
    { key: "firstName", label: "First Name" },
    { key: "lastName", label: "Last Name" },
  ];
  const coursesColumns = [
    { key: "title", label: "Course Title" },
    { key: "status", label: "In-Person Training Status" },
    { key: "hours", label: "In-Person Training Hours" }
  ];

  const isEditMode = pathname.split("/").includes("edit");

  // REQUESTS RESPONSES
  const fetchAllFarmers = React.useCallback(async (usersSelected = []) => {
    const res = await fetchFarmers(token);
    if (res.isOk) {
      const farmersDataToset = res.data.map((farmer) => { 
        return {
          ...farmer,
          userId: farmer.userId.toString(),
          fullname: `${farmer.firstName} ${farmer.lastName}`,
        };
      });
      const filteredData = farmersDataToset.filter((farmer) => {
        const selectedFarmersId = usersSelected.map(
          (selected) => selected.userId
        );
        return !selectedFarmersId.includes(farmer.userId);
      });
      setFarmersData(filteredData);
    } else {
      notify("Failed to fetch farmers", "error", 2000);
    }    
  }, [token]);

  const fetchInstructors = React.useCallback(async () => {
    const res = await fetchAllInstructors(token);
    if (res.isOk) {
      const instructorsDataToSet = res.data.map((instructor) => {
        return {
          ...instructor,
        };
      });
      return instructorsDataToSet
    } else {
      notify("Failed to fetch instructors", "error", 2000);
    }
  }, [token]);

  const fetchCohort = React.useCallback(async (coursesData = []) => {
    const splittedPath = pathname.split("/");
    const cohortId = splittedPath[splittedPath.length - 1];
    const res = await fetchCohortById(token, cohortId);
    if (res.isOk) {
      const {
        name,
        instructorDetails,
        instructor,
        gpsPoint, 
        participants,
        courses,
      } = res.data;
      setCohortInfo({
        name: name,
        gpsPoint : gpsPoint
      });
      setSelectedInstructors({ ...instructorDetails, userId: instructor });
      const coursePreSelected = coursesData.filter((course) => {
        const result = courses.map((course) => course.slug).includes(course.slug);
        return result 
      });

      const courseObject = coursePreSelected.map((course) => {
        const correspondingCourse = courses.find(
          (item) => item.slug === course.slug
        );
        if (correspondingCourse.allowedChapters) {
          return {
            ...course,
            completed: correspondingCourse.completed,
            started: correspondingCourse.started,
            sessionHours: correspondingCourse.sessionHours,
            allowedChapters: correspondingCourse.allowedChapters,
          };
        } else return {
          ...course, allowedChapters: [],
          completed: correspondingCourse.completed,
          started: correspondingCourse.started,
          sessionHours: correspondingCourse.sessionHours
        };
      });
      setSelectedCourses(courseObject);
      setStampSelectedCourses(courseObject);
      
      const participantToSet = participants.map((farmer) => {
        return {
          ...farmer,
          userId: farmer.participant.toString(),
          fullname: `${farmer.firstName} ${farmer.lastName}`,
          isAmbassador: farmer.isAmbassador !== 0
        };
      });
      return participantToSet;
    } else notify("Failed to fetch cohort", "error", 2000);
  }, [pathname, token]);

  const fetchCourses = React.useCallback(async () => {
    const res = await getAllCourses(token, program.id);
    if (res.isOk) {
      return res.data
    } else notify("Failed to fetch courses", "error", 2000);
  }, [token, program.id]);


  // COLUMNS CHOOSER - USED FOR PARTICIPANTS & SELECTED PARTICIPANTS
  const contentReady = (e) => {
    const columnChooserView = e.component.getView("columnChooserView");
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
      columnChooserView._popupContainer.option("position", {
        of: e.element,
        my: "right top",
        at: "right top",
        offset: "-20 60",
      });
    }
  };

  // fetch data used to have async calls 
  const fetchData = React.useCallback(async () => {
    const instructors = await fetchInstructors();
    const courses = await fetchCourses();
    if(isEditMode)  {
      const selectedUsers = await fetchCohort(courses);
      await fetchAllFarmers(selectedUsers)
      setSelectedFarmers(selectedUsers);
    }else{
      await fetchAllFarmers()
    }
    setInstructors(instructors);
    setAllCourses(courses);
    setLoadingCourse(false);
    setLoadingCohort(false);
    setLoadingParticipants(false);
  }, [isEditMode, fetchCohort, fetchInstructors,fetchCourses, fetchAllFarmers ]);

  React.useEffect(() => {
    //Prevent re-rendering
    if (isMounted.current === false){
      fetchData();
      // Stop rerendering
      isMounted.current = true
    }
  }, [ fetchData]);

  const handleDelete = (props) => {
    const deletedItem = selectedFarmers.filter(
      (item) => item.userId !== props.data.userId
    );
    setSelectedFarmers(deletedItem);
  };

  const handleInstructorChange = (e) => {
    setSelectedInstructors(e.selectedRowsData[0]);
    setIsDropDownOpen(false);
  };

  const onGridBoxOpened = React.useCallback((e) => {
    if (e.name === 'opened') {
      setIsDropDownOpen(e.value);
    }
  }, []);



  const addStampSelectionToCohort = () => {
    const updatedSelection = [...selectedFarmers, ...stampSelectedFarmers];
    setSelectedFarmers(updatedSelection);
    setStampSelectedFarmers([]);
    const filteredData = farmersData.filter((farmer) => {
      const selectedFarmersId = updatedSelection.map(
        (selected) => selected.userId
      );
      return !selectedFarmersId.includes(farmer.userId);
    });

    setFarmersData(filteredData);
  };

  const removeSelectedParticipant = () => {
      const updatedSelection = [...farmersToDelete];

      const updatedFarmersData = farmersData.concat(updatedSelection);
      const filteredSelectedFarmers = selectedFarmers.filter((selectedFarmer) => {
        const idsToRemove = updatedFarmersData.map((farmer) => farmer.userId);
        return !idsToRemove.includes(selectedFarmer.userId);
      });

      setSelectedFarmers(filteredSelectedFarmers);
      setFarmersToDelete([]);
      setFarmersData(updatedFarmersData);
  };

  const handleSelectionChange = (e) => {
    setStampSelectedFarmers(e.selectedRowsData);
  };

  const handleFarmersToDeleteChange = (e) => {
    setFarmersToDelete(e.selectedRowsData);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const valuesToUpdate = {
      ...cohortInfo,
      instructor: parseInt(selectedInstructors.userId) ? parseInt(selectedInstructors.userId) : null,
      participants: selectedFarmers.map((farmer) => ({
        participant: farmer.userId,
        isAmbassador: farmer.isAmbassador,
      })),
      courses: selectedCourses.map((course) => ({
        slug: course.slug,
        order: course.order,
        allowedChapters: course.allowedChapters,
        sessionHours:  (courseSessionHours[course.slug] === undefined ? course.sessionHours :  courseSessionHours[course.slug]),
        completed: (courseStatus[course.slug] === undefined ? course.completed :  isCompleted(courseStatus[course.slug])),
        started: (courseStatus[course.slug] === undefined ? course.started :  isStarted(courseStatus[course.slug]))
      })),
    };

    if (!isEditMode) {
      const res = await createCohort(token, valuesToUpdate);
      if (res.isOk) {
        notify("Cohort created", "info", 2000);
        history.push(getCohortEditPath(program.name, res.data.id));
      } else notify(res.message, "error", 2000);
    } else {
      const splittedPath = pathname.split("/");
      const cohortId = splittedPath[splittedPath.length - 1];
      const res = await updateCohort(token, {
        ...valuesToUpdate,
        id: cohortId,
      });
      if (res.isOk) {
        notify("Cohort updated", "info", 2000);
        history.push(getCohortsPath(program.name));
      } else {
        notify(res.message, "error", 2000);
      }
    }

    setLoading(false);
  };

  const handleSetAmbassador = (e) => {
    const famerIndex = selectedFarmers.findIndex(
      (item) => item.userId === e.row.data.userId
    );
    selectedFarmers[famerIndex].isAmbassador = !selectedFarmers[famerIndex]
      .isAmbassador;
    setSelectedFarmers([...selectedFarmers]);
  };

  // ** !! COULD BE REFACTO IN ./UTILS
  const renderCell = (props) => {
    const { data, value } = props;

    const renderAvatar = () => {
      return props.data.photo ? (
        <div
          style={{
            background: `url(${data.photo}) no-repeat #fff`,
            backgroundSize: "cover",
          }}
          className={"user-image"}
        />
        ) : (
        <div
          style={{ background: getAvatarColor(value) }}
          className={"text-image"}
        >
        {getFirstLetters(data.firstName, data.lastName)}
        </div>
        );
      };

    return (
      <div className={"userinfo"}>
        <div className={"image-container"}>{renderAvatar()}</div>
        <div className={"username"}>{props.value}</div>
        {data.isAmbassador ? <DXButton icon="favorites" text="" /> : ""}
      </div>
    );
  };
  // ** !!

  const dropBoxDisplay = (item) => {
    return item && `${item.firstName} ${item.lastName}`;
  };

  // COURSE PART
  const handleCoursesChange = async (e) => {
    const coureseSelection = await courseDataRef.current.instance.getSelectedRowsData();
    const courseSelectionWithChapters = coureseSelection.map((course) => {
      if (selectedCourses.find((selected) => selected.slug === course.slug)) {
        return {
          ...course,
          allowedChapters:
            selectedCourses[
              selectedCourses.findIndex(
                (selected) => selected.slug === course.slug
              )
            ].allowedChapters,
        };
      } else
        return {
          ...course,
          allowedChapters: [],
        };
    });

    const courseSelectedWithChaptersStamped = courseSelectionWithChapters.map(
      (course) => {
        if (stampSelectedCourses.find((item) => item.slug === course.slug)) {
          return stampSelectedCourses[
            stampSelectedCourses.findIndex((item) => item.slug === course.slug)
          ];
        } else {

          return course;
        }
      }
    );
    const previousSelectedCourses = [...selectedCourses];

    // If user is removing one course, add popup to inform 
    if(courseSelectedWithChaptersStamped.length < selectedCourses.length){
      const result = confirm(
        "Are you sure you want to delete access to a course ? It will delete all data associated including sessions, sessions participants and computed training effective hours",
        "Delete course"
      )
      result.then((dialogResult) => {
        if(dialogResult === true){
        setSelectedCourses(courseSelectedWithChaptersStamped);
        // We clicked on checked box, now it's unchecked
        // If we decided to not pursue, need the checkbox to be checked again for any confusion
        }else{
          courseDataRef.current.instance.selectRows(previousSelectedCourses.map(course => course.id), true);
        }
      })
    // If new course is added, just update
    }else{
      setSelectedCourses(courseSelectedWithChaptersStamped);
    }

  };

  const isCompleted = (props) => {
    if (props === undefined)
      return false
    if (props === "1") // not started
      return false
    else if (props === "2") // completed
      return true
    else if (props === "3") // in progress
      return false
  }

  const isStarted = (props) => {
    if (props === undefined)
      return false
    if (props === "1") // not started
      return false
    else if (props === "2") // completed
      return true
    else if (props === "3") // in progress
      return true
  }

  const renderCourseSessionHours = (props) => {
    return (
      <div>
        {(props.data.selected) ? renderSessionHours(props) : ""}
      </div>
    )
  }

  const renderSessionHours = (props) => {

    const getInitValForHours = (props) => {
      if (courseSessionHours[props.data.slug] === undefined) {
        return (props.data.sessionHours)
      }
      else {
        return (courseSessionHours[props.data.slug])
      }
    }
    const initValHours = getInitValForHours(props);

    const hoursChange = (props) => {
      setCourseSessionHours({ ...courseSessionHours, [props.element.id]: props.value })
    }

    return (
      <div>
        <NumberBox
          id={props.data.slug}
          value={initValHours}
          onValueChanged={hoursChange}
          tooltip="jkjk"
        />
      </div>
    )
  }

  const renderCourseStatusCell = (props) => {
    return (
      <div>
        {(props.data.selected) ? renderDropDown(props) : ""}
      </div>)
  };

  const handleCoordinatesChange = (value, key) => {
    let coordinates;
      const gps = cohortInfo.gpsPoint?.coordinates
      if (key === 'long'){
        coordinates = {
          type: "Point",
          coordinates : [value === '' ? null : parseFloat(value), gps ? gps[1] : null]
        }
      }else if(key === 'lat') {
        coordinates = {
          type: "Point",
          coordinates : [ gps ? gps[0] : null, value === '' ? null : parseFloat(value)]
        }
      }
      if(coordinates.coordinates[0] === null && coordinates.coordinates[1] === null){
        coordinates = null
      }
      const updatedValues = {
        ...cohortInfo,
        gpsPoint: coordinates
      };
      setCohortInfo(updatedValues);
  }


  const handleMapCenter = (e) => {
      const lat = e.latLng.lat()
      const lng = e.latLng.lng()
      const updatedValues = {
        ...cohortInfo,
        gpsPoint: {
          "type": "Point",
          "coordinates": [lng, lat]
        }
      };
      setCohortInfo(updatedValues);
  };

  const renderDropDown = (props) => {

    const itemList = [
      { value: "1", name: "Not started" },
      { value: "2", name: "Completed" },
      { value: "3", name: "In progress" }
    ]

    const getIdName = (props) => {
      if (courseStatus[props.data.slug] === undefined) {
        if ((props.data.started !== undefined) && (props.data.completed !== undefined)) {
          if (!props.data.started && !props.data.completed)
            return (itemList[0].value) // "1", not started
          if (props.data.started && !props.data.completed)
            return (itemList[2].value) // "3", in progress
          if (props.data.completed)
            return (itemList[1].value) // "2", completed
          return (itemList[0].value) // "1", not started
        }
      }
      return courseStatus[props.data.slug]
    }

    const selectIdName = (props) => {
      setCourseStatus({ ...courseStatus, [props.element.id]: props.value  })
    }

    const initVal = getIdName(props);

    return (
      <div> <Select
        id={props.data.slug}
        options={itemList}
        value={initVal}
        onChange={selectIdName}
        tooltip="Select the cohort status, precise training hours"
      />
      </div>);
  }

  const renderCourseCell = (props) => {
    const { data, value } = props;

    const renderAvatar = () => {
      return props.data.imageUrl ? (
        <div
          style={{
            background: `url(${data.imageUrl}) no-repeat #fff`,
            backgroundSize: "cover",
          }}
          className={"user-image"}
        />
      ) : (
        <div
          style={{ background: getAvatarColor(value) }}
          className={"text-image"}
        >
          {value.substring(0, 2)}
        </div>
      );
    };

    return (
      <div className={"userinfo"} >
        <div className={"image-container"}>{renderAvatar()}</div>
        <div className={"username"}>{props.value}</div>
      </div>
    );
  };

  const renderAllCourses = () => {
    const coursesData = allCourses.map((course) => {
      const selected = selectedCourses.some(
        (item) => item.slug === course.slug
      );
      const courseStatus = selectedCourses.find(
        (item) => item.slug === course.slug
      );
      if (courseStatus) {
        course.sessionHours = courseStatus.sessionHours;
        course.started = courseStatus.started;
        course.completed = courseStatus.completed;
      }
      return {
        ...course,
        selected,
      };
    });
    return coursesData;
  };

  const handleSendSms = async () => {
    const splittedPath = pathname.split("/");
    const cohortId = splittedPath[splittedPath.length - 1];
    const res = await sendSmsToCohort(token, {
      messageContent: messageSms,
      cohortId: cohortId,
    });
    if (res.isOk) {
      notify(`Sms was sent to cohort was sent successfully`, "success", 2000);
    } else {
      notify(`Failed to send sms`, "error", 2000);
    }
    handleCloseSmsPopup();
  };

  const handleCloseSmsPopup = () => {
    setShowSmsPopup(false);
    setMessageSms("");
  };

  const handleSendSmsClick = () => setShowSmsPopup(true);

  const handleMessageChange = (name, value) => {
    // If second parameter is undefined, it's the message
    if(name === "message"){
      setMessageSms(value)

    }else{
      setOptions(prevOptions => ({
        ...prevOptions,
        [name]: {
          ...prevOptions[name],
          value: value
        }
      }));
    }

  };

  const handleSendMessage = () => {
    if(options.method.value === 'SMS') {
      handleSendSms()
    } else if(options.method.value === 'Notification') {
      handleSendNotification()
    }
  }


  const handleSendNotification = async () => {
    const splittedPath = pathname.split("/");
    const cohortId = splittedPath[splittedPath.length - 1];
    const response = await sendNotificationToCohort(token, {
      message: messageSms,
      cohortId: cohortId.toString(),
    })
    if (response.isOk) {
      notify(`Notification sent to cohort ${cohortId} successfully`, "success", 5000);
    } else {
      notify(`Failed to send notification`, "error", 2000);
    }
    handleCloseSmsPopup();
  }


  const filteredCourses = ["selected", "=", true];

  const handleCancel = () => {
    history.push(getCohortsPath(program.name))
  };

  const onValidate = async (e) => {
    const res = await e.validationGroup.validate();
    if (res.brokenRules.length > 0) {
      notify("Please fill in all required details", "error", 4000);
    } else {
      await handleSubmit();
    }
  };

  // FIRST FIELDS OF THE PAGE
  const mapCenter = useMemo(
    () => {
      if (cohortInfo.gpsPoint) {
        return  {lat: cohortInfo.gpsPoint.coordinates[1], lng: cohortInfo.gpsPoint.coordinates[0]}
      } else {
        return {lat: 0, lng: 0}
      }
    },[cohortInfo.gpsPoint]);


  // THE WHOLE PAGE
  return (
    <>
      <Form formData={cohortInfo} validationGroup="cohortValidationGroup">
        <SimpleItem
          dataField={"name"}
          editorOptions={{
            stylingMode: "outlined",
          }}
          validationRules={validationRules.name}
        />
         <GroupItem colCount={2}>
          <SimpleItem
            dataField="longitude"
            render={() => (
              <div>
                <TextBox
                  id="longitude"
                  value={cohortInfo.gpsPoint?.coordinates[0]}
                  onValueChange={(value) =>handleCoordinatesChange(value, 'long')}
                  stylingMode="outlined"
                >
                </TextBox>
                <Tooltip
                  target="#longitude"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  closeOnOutsideClick={false}
                >
                  <div>GPS Coordinates (longitude)</div>
                </Tooltip>
              </div>
            )}
          />
          <SimpleItem
            dataField="latitude"
            render={() => (
              <div>
                <TextBox
                  id="latitude"
                  value={cohortInfo.gpsPoint?.coordinates[1]}
                  onValueChange={(value) =>handleCoordinatesChange(value, 'lat')}
                  stylingMode="outlined"
                >
                </TextBox>
                <Tooltip
                  target="#latitude"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  closeOnOutsideClick={false}
                >
                  <div>GPS Coordinates (latitude)</div>
                </Tooltip>
              </div>
            )}
          />
          </GroupItem>
          <SimpleItem
            dataField="map"
            render={() => (
              <MapLayer
                center={mapCenter}
                onClick={handleMapCenter}
                polygons={[]}
              />
            )}
          />
        <SimpleItem
          dataField={"instructor"}
          render={() => (
            <DropDownBox
              ref={dropdownRef}
              value={selectedInstructors}
              valueExpr="userId"
              deferRendering={false}
              displayExpr={dropBoxDisplay}
              placeholder={ selectedInstructors ? selectedInstructors.firstName + " " + selectedInstructors.lastName :"Select a value..."}
              dataSource={instructors}
              onOptionChanged={onGridBoxOpened}
              stylingMode="outlined"
              opened={isDropDownOpen}
              contentRender={() => (
                <DataGrid
                  id="gridContainer"
                  ref={instructorDataRef}
                  dataSource={instructors}
                  showColumnLines={true}
                  showRowLines={false}
                  hoverStateEnabled={true}
                  showBorders={true}
                  columnAutoWidth={true}
                  selectedRowKeys={selectedInstructors}
                  keyExpr="userId"
                  allowColumnResizing={true}
                  onSelectionChanged={handleInstructorChange}
                  onRowRemoving={handleDelete}
                  height={300}
                >
                  <Selection mode="single" />
                  <Scrolling mode="standard" />
                  <FilterRow visible={true} />
                  {instructorColumns.map((column) => {
                    return column.key === "firstName" ? (
                      <Column
                        key={column.key}
                        dataField={column.key}
                        caption={column.label}
                        dataType={column.dataType}
                        cellRender={renderCell}
                      />
                    ) : (
                      <Column
                        key={column.key}
                        dataField={column.key}
                        caption={column.label}
                        dataType={column.dataType}
                      />
                    );
                  })}
                </DataGrid>
              )}
            />
          )}
        />

      </Form>
          <h5>Selected cohort participants</h5>
          <div className="numberInfo">{`Total selected participants: ${selectedFarmers.length}`}</div>
          {loadingCohort ? <Spinner /> :
           <DataGrid
           id="gridContainer"
           ref={participantsDataRef}
           dataSource={selectedFarmers}
           showColumnLines={true}
           showRowLines={false}
           hoverStateEnabled={true}
           showBorders={true}
           allowColumnResizing={true}
           keyExpr="userId"
           onRowRemoving={handleDelete}
           onSelectionChanged={handleFarmersToDeleteChange}
           onContentReady={contentReady}
           width={"100%"}
           columnAutoWidth={true}
         >
           <Selection
             mode="multiple"
             selectAllMode={true}
             showCheckBoxesMode={"onClick"}
             icon="checkbox"
           />
           <ColumnChooser
             visible= {true}
             enabled={true}
             mode="select"
             height={40 + (selectedParticipantsColumns.length -1 ) * 60}
             icon="checkbox"
           />
           <Scrolling columnRenderingMode="virtual" />
           <Paging enabled={true} pageSize={10} />
           <FilterRow visible={farmersData.length > 0} />
           {selectedParticipantsColumns.map((column) => {
             return column.key === "fullname" ? (
               <Column
                 key={column.key}
                 dataField={column.key}
                 caption={column.label}
                 dataType={column.dataType}
                 cellRender={renderCell}
                 visible={column.visible}
               />
             ) : (
               <Column
                 key={column.key}
                 dataField={column.key}
                 caption={column.label}
                 dataType={column.dataType}
                 visible={column.visible}
               />
             );
           })}
           <Column type="buttons" width={50}>
             <GridButton
               hint="Name Ambassador"
               icon="favorites"
               visible={true}
               onClick={(e) => handleSetAmbassador(e)}
             />
             {/* FEATURE ALREADY POSSIBLE BY ARROWRIGHT : */}
             {/* <GridButton name="delete" /> */}
           </Column>
           {/* <Editing  --> Need to be import if used
             mode="row"
             allowUpdating={true}
             allowDeleting={true}
             useIcons={true}
             texts={{
               confirmDeleteMessage:
                 "Are you sure you want to remove this farmer from the cohort?",
             }}
           /> */}
         </DataGrid>
          
          }
         
        <div className={ "actionButtons"}>
          <DXButton
            className={"moveToCohortButton"}
            onClick={addStampSelectionToCohort}
            icon={"arrowup"}
            text=""
          />
          <DXButton
            className={"moveToCohortButton"}
            onClick={removeSelectedParticipant}
            icon={ "arrowdown"}
            text=""
          />
        </div>
          <h5>All participants</h5>
          <div className="numberInfo">{`Total participants: ${farmersData.length}`}</div>
          {loadingParticipants ? <Spinner /> : 
           <DataGrid
             id="gridContainer"
             ref={farmersDataRef}
             dataSource={farmersData}
             showColumnLines={true}
             showRowLines={false}
             hoverStateEnabled={true}
             showBorders={true}
             allowColumnResizing={true}
             keyExpr="userId"
             onSelectionChanged={handleSelectionChange}
             onContentReady={contentReady}
             width={"100%"}
             columnAutoWidth={true}
           >
             <Selection
               mode="multiple"
               selectAllMode={true}
               showCheckBoxesMode={"onClick"}
               icon="checkbox"
             />
             <ColumnChooser
               enabled={true}
               mode="select"
               height={40 + (allParticipantsColumns.length - 1) * 60}
               icon="checkbox"
             />
             <Scrolling columnRenderingMode="virtual" />
             <Paging enabled={true} pageSize={10} />
             <FilterRow visible={farmersData.length > 0} />
             {allParticipantsColumns.map((column) => {
               return column.key === "fullname" ? (
                 <Column
                   key={column.key}
                   dataField={column.key}
                   caption={column.label}
                   dataType={column.dataType}
                   cellRender={renderCell}
                   visible={column.visible}
                 />
               ) : (
                 <Column
                   key={column.key}
                   dataField={column.key}
                   caption={column.label}
                   dataType={column.dataType}
                   visible={column.visible}
                 />
               );
             })}
           </DataGrid>
          
          }
         

      <h5>Courses</h5>
      {loadingCourse ? 
      <Spinner /> : 
       <DataGrid
       id="gridContainer"
       ref={courseDataRef}
       dataSource={renderAllCourses()}
       showColumnLines={true}
       showRowLines={false}
       hoverStateEnabled={true}
       showBorders={true}
       columnAutoWidth={true}
       keyExpr="id"
       allowColumnResizing={true}
       defaultSelectionFilter={filteredCourses}
       onSelectionChanged={handleCoursesChange}
     >
       <Selection
         mode="multiple"
         selectAllMode={true}
         showCheckBoxesMode={"always"}
         icon="checkbox"
         deferred={true}
       />
       <Scrolling mode="infinite" />
       <FilterRow visible={farmersData.length > 0} />
       <Paging enabled={true} pageSize={10} />
       {coursesColumns.map((column) => {
         return column.key === "title" ? (
           <Column
             key={column.key}
             dataField={column.key}
             caption={column.label}
             dataType={column.dataType}
             cellRender={renderCourseCell}
           />
         ) : column.key === "status" ? (
           <Column
             key={column.key}
             dataField={column.key}
             caption={column.label}
             dataType={column.dataType}
             cellRender={renderCourseStatusCell}
           />
         ) : (
           <Column
             key={column.key}
             dataField={column.key}
             caption={column.label}
             dataType={column.dataType}
             cellRender={renderCourseSessionHours}
           />
         );
       },
       )}
     </DataGrid>
      
      }
     

      <div className={"buttons"}>
        <Button
          disabled={loadingCohort && loadingParticipants && loadingCourse}
          text="Validate"
          onClick={onValidate}
          validationGroup={"cohortValidationGroup"}
          loading={loading}
          useSubmitBehavior={true}
        />
        <Button text="Cancel" onClick={handleCancel} buttonStyle={"secondary"}/>
        {isEditMode && (
          <Button
            text="Send message to cohort"
            onClick={handleSendSmsClick}
          />
        )}
      </div>
      
      <MessagePopup
          loading={loading}
          open={showSmsPopup}
          message={messageSms}
          options={options}
          onClose={handleCloseSmsPopup}
          onMessageChange={handleMessageChange}
          onSendSms={handleSendMessage}
          type={notificationCohort}
          previewMessage={messageSms.length > 0 ? messageSms : ""}
        />
    </>
  );
};

export default CohortData;

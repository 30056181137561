import { Popup, TextArea, Switch, RadioGroup} from "devextreme-react";
import { Button } from "../../components";
import { Button as DXButton} from 'devextreme-react/button';
import { useScreenSize } from "../../utils/media-query";
import { notificationCohort, sessionAlert } from "../../utils/utils";

import React from "react";
import "./messagePopup.scss";

const MessagePopup = ({
  open,
  onClose,
  message,
  type,
  options, 
  onMessageChange,
  previewMessage,
  onSendSms,
  loading,
}) => {

  const choices = ["Notification","SMS"];
  const { isSmall, isXSmall, isMedium, isLarge, isXLarge } = useScreenSize();

  const renderTitle = () => {
    switch (type) {
      case sessionAlert:
        return "Inform cohort of new session";
      case notificationCohort:
        return "Send notification to cohort";
      default:
        return "Notification";
    }
  }
  const renderChoices = () => {
    return (
      <div className="flex-row-wrap">
        {/* For each option, render a switch */}
        {Object.keys(options).map((key) => {
          if(key === "method"){
            return (
              <div key={key} className="flex-column">
              <div>{options[key].label}</div>
              <RadioGroup items={choices} defaultValue={choices[0]} layout="horizontal" onValueChange={(value) => onMessageChange(key, value)} />
              {options[key].value === "SMS" && renderWarning()}
            </div>

            )

          } else {
            return (
              <div key={key} className="flex-column">
              <div>{options[key].label}</div>
              <Switch 
                defaultValue={options[key].value}  
                onValueChanged={(e) => onMessageChange(key, e.value)}
              />
            </div>
            )   
          }

  })}
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className={"flex-buttons"}>
      <Button
        text={"Send"}
        onClick={onSendSms}
        className={"smsButton"}
        loading={loading}
      />
      <Button
        text={"Cancel"}
        onClick={()=>{open = false; onClose()}}
        className={"cancelButton"}
        loading={loading}
      />
      </div>
    )
  }

  const renderWarning = () => {
    return (
      <div className={"flex-row"}>
        <DXButton icon="warning"/>
        Please consider that SMS is costly
      </div>
    )
  };

  return (
    <Popup
      visible={open}
      onHiding={onClose}
      dragEnabled={false}
      closeOnOutsideClick={true}
      showTitle={true}
      title={renderTitle}
      width={isSmall || isXSmall ? 300 : 500}
      height={type !== sessionAlert && type !== notificationCohort ? 380 : 500}
    >
      {
        (isSmall || isXSmall) && renderButtons()
      }
      {
       (type === sessionAlert || notificationCohort) && renderChoices()
      }
      <div className="dx-field-label">Message</div>
      <TextArea
        value={message}
        onValueChange={(e)=>{ type === sessionAlert || type === notificationCohort ? onMessageChange("message", e) : onMessageChange(e)}}
        valueChangeEvent={"keyup"}
        placeholder={"Enter your message"}
      />
      <div
        className="dx-field-label"
        style={{
          marginTop: "1rem",
        }}
      >
        Message preview
      </div>
      <TextArea
        style={{
          marginTop: "2rem",
          marginBottom: "1rem",
        }}
        readOnly={true}
        stylingMode="filled"
        value={previewMessage}
      />

      {
        (isMedium || isLarge || isXLarge) && renderButtons()
      }
    </Popup>
  );
};

export default MessagePopup;
